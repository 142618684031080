import React, { useState, useEffect, useRef }  from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useCookies } from 'react-cookie';
import FrontServiciosAPI from '../servicios/FrontServicios';
import { Config } from '../comun';
import md5 from 'crypto-js/md5'; 
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Constantes from '../comun/Constantes';
import CuerpoCarga from '../comun/CuerpoCarga';

function Inicio (){
    const [pagina, setPagina] = useState(null);
    const [logueando, setLogueando] = useState(false);
    const [, setCookie] = useCookies(['user']);
    const [cargando, setCargando] = useState(false);
    const toast = useRef(null);

    async function cargarPaginaInicio() {
        setCargando(true);
        const response = await FrontServiciosAPI.getFront('paginainicio');
        if(response.ok){
            const html = await response.json();
            let strpagina = html.pagina.replaceAll('<link href=\"','<link href=\"'+Config.cloudFront+html.materia.id+'/paginainicio/');
            strpagina = strpagina.replaceAll('src=\"','src=\"'+Config.cloudFront+html.materia.id+'/paginainicio/');
            var parser = new DOMParser();
            var doc = parser.parseFromString(strpagina, 'text/html');
            document.title=html.materia.nombre;
            document.getElementById('favicon').href=`${Config.cloudFront}${html.materia.id}/recursos/favicon.png`;
            for (let i = 0; i < doc.head.childNodes.length; i++) {
                if(doc.head.childNodes[i].tagName==='LINK' || doc.head.childNodes[i].tagName==='SCRIPT' || doc.head.childNodes[i].tagName==='STYLE'){
                    document.head.appendChild(doc.head.childNodes[i]);
                }
            }
            if(doc.body.getAttribute('class')!==''){
                document.body.setAttribute('class',doc.body.getAttribute('class'));
            }
            
            setPagina(doc.body.innerHTML);
            //setPagina(strpagina);
            setCookie(Constantes.NOMBRECKMATERIA, html.materia, { path: '/' });
        }else{
            const error = await response.json();
            console.log('error',error);
        }
        setCargando(false);
    };

    useEffect( () => {
		cargarPaginaInicio();
	}, []);

    const _entrar = async () => {
        if(document.getElementById('acuerdo') && !document.getElementById('acuerdo').checked){
            toast.current.show({severity:'warn', detail:'Por favor marque la casilla de acuerdo', sticky: true});
            return;
        }
        setLogueando(true);
        const response = await FrontServiciosAPI.postFront('entrar',{usuario: document.getElementById('usuario').value, clave: md5(document.getElementById('clave').value).toString()});
        if(!response.ok){
            const errormsj = await response.json();
            toast.current.show({severity:'warn', detail:errormsj, sticky: true});
        }else{
            let datosUsuario = await response.json();
            //console.log('usuario',datosUsuario);
            //datosUsuario.usuario.datos = JSON.parse(datosUsuario.usuario.datos);
            setCookie(Constantes.NOMBRECKUSUARIO, datosUsuario.estudiante, { path: '/' });
            setCookie(Constantes.NOMBRECKCOOKIE, datosUsuario.cookie, { path: '/' });
            setCookie(Constantes.NOMBRECKOTRASMATERIAS, datosUsuario.materias, { path: '/' });
            
            window.location.reload(false);
        }
        setLogueando(false);
    }

    const CargarPagina = () => {
        return ReactHtmlParser(pagina, {
            transform: (node) => {
                if (node.type === 'tag' && node.name === 'button') {
                    return <Button key="bt1" type="button" loading={logueando} className={node.attribs.class} label={node.children[0].data} onClick={() =>_entrar()} />;
                }
            } 
        });
    }

    return <div><Toast ref={toast} /><CuerpoCarga cargando={cargando}>{pagina?<CargarPagina key="1" />:null}</CuerpoCarga></div>;
}

export default Inicio;